import React, { useState } from 'react'
import Helmet from 'react-helmet'
import { Link, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import tw, { css } from 'twin.macro'
import Layout from '../components/layout'
import MineralSwim from '../components/ComparisonTables/MineralSwim'
import MineralSwimV2 from '../components/ComparisonTables/MineralSwimV2'
import OzoneSwim from '../components/ComparisonTables/OzoneSwim'
import EcoSwim from '../components/ComparisonTables/EcoSwim'
import EcoSwimV2 from '../components/ComparisonTables/EcoSwimV2'
import '../components/PageBannerImage/index.css'
import MineralSwimV1Img from '../images/products/MineralSwimV2_Purifier.png'
import MineralSwimV2Img from '../images/products/MineralSwimV2_NEW-Box.png'
import EcoswimV2Img from '../images/products/EcoswimV2_NEW-Box.png'
import { CompareSelected } from '../components/CompareModal/CompareSelected'
import {products} from '../data/products'
import { CompareModal } from '../components/CompareModal/CompareModal'
import { WarningModal } from '../components/CompareModal/WarningModal'
import BulletLogo from '../components/svg/BulletLogo'

const style = css`
	${tw`border border-white m-0 p-0 w-full`}
	border-collapse: collapse;
	table-layout: fixed;

	caption {
		${tw`text-2xl bg-gray-200 w-full mx-2 my-3 p-2`}
	}

	tr {
		${tw`bg-gray-50 border-2 border-white p-3`}
	}

	thead tr th,
	tbody tr td {
		${tw`p-3 text-center`}
	}

	thead tr th {
		${tw`bg-white text-sm`}
	}

	tbody tr td:first-of-type {
		${tw`text-left md:text-right bg-white border-r-2 border-r-white `}
	}

	@media screen and (max-width: 600px) {
		border: 0;
		table-layout: auto;

		caption {
			${tw`text-xl hidden`}
		}

		thead {
			/* border: none;
			clip: rect(0 0 0 0);
			height: 1px;
			margin: -1px;
			overflow: hidden;
			padding: 0;
			position: absolute;
			width: 1px; */
		}

		tr {
			border-bottom: 3px solid #ddd;
			display: block;
			margin-bottom: 0.625em;
		}

		tbody tr.trow-title td:first-of-type {
			${tw`block`}
		}

		thead tr th {
			min-width: 200px;
		}

		tbody tr td {
			min-width: 200px;
			${tw`text-sm text-center border-b-gray-300`}
		}

		tbody tr td::before {
			content: attr(data-label);
			float: left;
			font-weight: bold;
			text-transform: uppercase;
			display: none;
			/* width: 100%; */
		}

		tbody tr td:last-child {
			border-bottom: 0;
		}
	}
`

const ComparePage = (props) => {
	const [selectedProducts, setSelectedProducts] = useState([])
	const [showCompareModal, setShowCompareModal] = useState(false)
	const [showWarningModal, setShowWarningModal] = useState(false)
	const mineralSwimBox = props.data.mineralSwimBox.nodes[0].gatsbyImageData
	const ozoneSwimBox = props.data.ozoneSwimBox.nodes[0].gatsbyImageData
	const ecoSwimBox = props.data.ecoSwimBox.nodes[0].gatsbyImageData
	const mineralSwimV2Products = props.data.productData.edges
		.filter(({ node }) => /V2/.test(node.series) && node)
		.map(({ node }) => node)
	const mineralSwimProducts = props.data.productData.edges
		.filter(({ node }) => /PRO Series|O₃ Series/.test(node.series) && node)
		.map(({ node }) => node)
	const ozoneSwimProducts = props.data.productData.edges
		.filter(({ node }) => /Ozone Swim Series/.test(node.series) && node)
		.map(({ node }) => node)
	const ecoSwimProducts = props.data.productData.edges
		.filter(({ node }) => {
			return /Eco Swim/i.test(node.series) && node && node.title.includes('Eco Swim ES')
		})
		.map(({ node }) => node)
		const handleSelectProduct = (product) => {
			if (!selectedProducts.includes(product)) {
				if (selectedProducts.length <= 3) {
					setSelectedProducts([...selectedProducts, product])
				} else {
					setShowWarningModal(true)
				}
			} else {
				setSelectedProducts(selectedProducts.filter((p) => p !== product))
			}
		}
		const handleClearAll = () => {
			setSelectedProducts([])
		}
		const getObjectPosition = () => {
			if (typeof window !== 'undefined' && window.innerWidth >= 1024) {
				return ['center'];
			} else {
				return ['right'];
			}
		};
		
	return (
		<Layout className="ComparePage">
			<Helmet>
				<title>Mineral Swim - Product Comparison</title>
				<meta
					name="description"
					content="Click on the product below to see available options and comparison
					information"
				/>
				<meta name="keywords" content="Product Comparison" />
				<link rel="canonical" href="https://mineralswim.com/compare/" />
			</Helmet>
			<div className="fixed top-0 w-full h-full" style={{}}>
				<GatsbyImage
							image={props.data.compareBannerGatsby.nodes[0].gatsbyImageData}
							className="w-screen h-full lg:object-center object-right object-cover"
							loading="eager"
							alt="Test"
							objectPosition={getObjectPosition()}
						/>
				</div>
				{selectedProducts.length > 0 && !showWarningModal && !showCompareModal && <CompareSelected products={products({mineralSwimV2Products,mineralSwimProducts,ozoneSwimProducts,ecoSwimProducts})} selectedProducts={selectedProducts} handleSelectProduct={handleSelectProduct} handleClearAll={handleClearAll} setShowCompareModal={setShowCompareModal}/> }
				{showCompareModal && <CompareModal 
				setShowCompareModal={setShowCompareModal} 
				id={"compare-modal"}
				style={style}
						products={products({mineralSwimV2Products,mineralSwimProducts,ozoneSwimProducts,ecoSwimProducts})}
						selectedProducts={selectedProducts} 
				/>}
{showWarningModal && <WarningModal setShowWarningModal={setShowWarningModal} />}		
			<main id='noScroll' style={{ position: 'relative', zIndex: 2,}}>
								<section className='w-screen flex flex-col justify-start' style={{ position: 'relative', zIndex: 2, height:"72vh" }} >		
				
					<header className='main-page-header bg-fixed bg-right lg:bg-center'>
							<div className='mx-auto max-w-screen-xl h-full grid grid-cols-6 lg:grid-cols-12 lg:py-10 px-5 md:px-10'>
								<div className='hidden lg:flex col-span-6'></div>
								
								<div className='text-center lg:text-left col-span-6 h-full flex flex-col justify-center md:gap-5 lg:gap-0'>
									<div className='bg-white bg-opacity-20 p-5 rounded-2xl '>
									<h1 className='text-black text-2xl md:text-4xl font-semibold'>Explore Our Mineral Swim<span className='text-lg md:text-xl text-black align-text-top'>™</span> Range</h1>
									<div className='flex w-full justify-center items-center lg:justify-start'>
									<div className='w-20 md:w-16 h-1 mb-4 mt-4 md:mt-2 border-2 border-black'/>		
									</div>
									<p className='text-black md:text-base leading-6'>Explore our Mineral Swim™ range to determine the best solution for you and your pool. 
										</p>				
										<div className='w-full flex justify-center lg:justify-start mt-7 md:mt-10'>			              
									<Link to='#categories'><p className='text-white text-base font-bold bg-gray-800 px-8 rounded-lg py-1 hover:bg-gray-200 hover:text-black cursor-pointer uppercase'>Learn more</p>	
									</Link>		
									</div>
									</div>								
									</div>                
							</div>
								</header>
								</section>
	
				<section className='w-screen bg-white'>
				<div className="max-w-screen-xl mx-auto px-2 font-display items-center text-center pb-6">
				<p  id='categories'  className="py-6 px-5 w-full text-center font-display font-medium text-lg md:text-2xl">
				Discover our range of products in our comprehensive comparison chart. Click “compare” to evaluate the key differences and unique features. Explore how each model meets your pool purification needs for a cleaner, healthier swimming experience. 
				</p>
					<div className="w-full flex flex-col md:flex-row mb-4">
						<Link
							tw="flex flex-col justify-between pt-4 bg-gray-100 hover:bg-gray-50 cursor-pointer rounded-lg mb-2 md:mr-2 shadow"
							to="#mineralswimV2"
						>
							<p className='px-4'><b>Mineral Swim<span className='text-xs md:text-xs text-black align-text-top'>™</span> V2 PRO</b><br/><div className='flex text-left'><div><BulletLogo  className="w-2"/></div><p>Combines ozone purification & mineral chlorination & 100% natural Dead Sea Minerals</p></div>
							</p>
							<img src={MineralSwimV2Img} alt='Mineral Swim V1' className='w-full object-contain -mt-16 -mb-10'/>
						</Link>
						<Link
							tw="flex flex-col justify-between pt-4 bg-gray-100 hover:bg-gray-50 cursor-pointer rounded-lg mb-2 md:mr-2 shadow "
							to="#mineralswim"
						>
							<p className='px-4'>
							<b>Mineral Swim<span className='text-xs md:text-xs text-black align-text-top'>™</span> V2 Purifier</b><br/><div className='flex text-left'><div><BulletLogo className="w-2"/></div> <p>Natural ozone purification. Use with an existing chlorinator.</p></div>
							</p>
							<img src={MineralSwimV1Img} alt='Mineral Swim V1' className='w-full object-contain -mt-16 -mb-10'/>
						</Link>
						<Link
							tw="flex flex-col justify-between pt-4 bg-gray-100 hover:bg-gray-50 cursor-pointer rounded-lg mb-2 md:mr-2 shadow"
							to="#ecoswimV2"
						>
							<p className='px-4'>
							<b>Ecoswim&reg;</b><br/><div className='flex text-left'><div><BulletLogo className="w-2"/></div><p>Turns either salt/minerals into pure sanitiser</p></div></p>
							<img src={EcoswimV2Img} alt='Mineral Swim V1' className='w-full object-contain -mt-16 -mb-10'/>
						</Link>
					</div>
					<MineralSwimV2
						id="mineralswimV2"
						style={style}
						mineralSwimV2Products={mineralSwimV2Products}
						handleSelectProduct={handleSelectProduct}
						selectedProducts={selectedProducts}
					/>
					<MineralSwim
						id="mineralswim"
						style={style}
						mineralSwimProducts={mineralSwimProducts}
						handleSelectProduct={handleSelectProduct}
						selectedProducts={selectedProducts}
					/>
					<OzoneSwim
						id="ozoneswim"
						style={style}
						ozoneSwimProducts={ozoneSwimProducts}
						handleSelectProduct={handleSelectProduct}
						selectedProducts={selectedProducts}
					/>
					<EcoSwimV2
						id="ecoswimV2"
						style={style}
						mineralSwimProducts={mineralSwimProducts}
						handleSelectProduct={handleSelectProduct}
						selectedProducts={selectedProducts}
					/>
					<EcoSwim
						id="ecoswim"
						style={style}
						ecoSwimProducts={ecoSwimProducts}
						handleSelectProduct={handleSelectProduct}
						selectedProducts={selectedProducts}
					/>
				</div>
				</section>
			</main>
		</Layout>
	)
}

export const ComparePageQuery = graphql`
	query productDataQueryAU {
		productData: allContentfulProduct(
			filter: { brand: { regex: "/Swim/" }, node_locale: { eq: "en-AU" } }
			sort: { fields: popularity, order: DESC }
		) {
			edges {
				node {
					title: seoTitle
					slug
					brand
					series
					sku
					price
					specifications {
						spec
						detail
					}
					images {
						title
						alt: description
						gatsbyImageData(
							layout: CONSTRAINED
							width: 220
							quality: 70
							placeholder: BLURRED
							formats: [AUTO, WEBP]
						)
					}
				}
			}
		}
		mineralSwimBox: allContentfulAsset(
			filter: {
				contentful_id: { eq: "2J00c5KA4b1QUa0458eIYV" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 640
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		ozoneSwimBox: allContentfulAsset(
			filter: {
				contentful_id: { eq: "4H0FnQz6jOQ6OkgN9m3Pvp" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 640
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
		ecoSwimBox: allContentfulAsset(
			filter: {
				contentful_id: { eq: "Jwk7WM1krtaDMp9yf9xkV" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description
				gatsbyImageData(
					layout: CONSTRAINED
					width: 640
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
			compareBannerGatsby: allContentfulAsset(
			filter: {
				contentful_id: { eq: "3qkNGXMusvP7p211HzRvqQ" }
				node_locale: { eq: "en-AU" }
			}
		) {
			nodes {
				title
				alt: description

				gatsbyImageData(
					layout: FULL_WIDTH
					width: 1600
					height: 900
					quality: 80
					placeholder: BLURRED
					formats: [AUTO, WEBP]
				)
			}
		}
	}
`

export default ComparePage
