

import EcoSwimv2img from '../images/Ecoswim_Carton+ControlUnit+Chlorinator.png'
export const products = ({mineralSwimV2Products,mineralSwimProducts,ozoneSwimProducts,ecoSwimProducts})=>{

  return [
    // V2
    {
      name: 'Mineral Swim™️ V2 PRO40',
      display: 'V2 Pro 40',
      productCode: '10-110-0011',
      price: '$2,899',
      image: {
        gatsby: true,
        image: mineralSwimV2Products[0].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 135,000 L',
        nominalChlorineOutput: '39 G/HR + Ozone',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: true,
        purifierModule: true,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost',
        tdsLevel: '3500-6500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '5 years (3 years full + 2 years pro rata)',
      electrolyicCell: '5 years (3 years full + 2 years pro rata)',
     },
    upgrades:{
      phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    },
    url:'https://www.maytronics.com.au/product/mineral-swim-v2-pro40/'      
    },
    {
      name: 'Mineral Swim™️ V2 PRO25',
      display: 'V2 Pro 25',
      productCode: '10-110-0010',
      price: '$2,499',
      image: {
        gatsby: true,
        image: mineralSwimV2Products[1].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 95,000 L',
        nominalChlorineOutput: '26 G/HR + Ozone',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: true,
        purifierModule: true,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost',
        tdsLevel: '3500-6500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '5 years (3 years full + 2 years pro rata)',
      electrolyicCell: '5 years (3 years full + 2 years pro rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    } ,
    url:'https://www.maytronics.com.au/product/mineral-swim-v2-pro25/'        
    },
    {
      name: 'Mineral Swim™️ V2 PRO20',
      display: 'V2 Pro 20',
      productCode: '10-110-0009',
      price: '$2,299',
      image: {
        gatsby: true,
        image: mineralSwimV2Products[2].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 75,000 L',
        nominalChlorineOutput: '20 G/HR + Ozone',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: true,
        purifierModule: true,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost',
        tdsLevel: '3500-6500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '5 years (3 years full + 2 years pro rata)',
      electrolyicCell: '5 years (3 years full + 2 years pro rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }     ,
    url:'https://www.maytronics.com.au/product/mineral-swim-v2-pro20/'    
    },
    {
      name: 'Mineral Swim™️ V2 Purifer 400VA',
      display: 'V2 Purifer 400VA',
      productCode: '10-110-0014',
      price: '$1,599',
      image: {
        gatsby: true,
        image: mineralSwimV2Products[3].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 160,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: false,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: false,
        purifierModule: true,
        additionalChlorinator: true,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost',
        tdsLevel: '',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '5 years (3 years full + 2 years pro rata)',
      electrolyicCell: '-',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/mineral-swim-v2-purifier-400va/'       
    },
    // V1
    {
      name: 'Mineral Swim™️ PRO 3',
      display: 'V1 Pro 3',
      productCode: 'BR-600.03-VS',
      price: '$3,590',
      image: {
        gatsby: true,
        image: mineralSwimProducts[0].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 110,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '10 years',
      electrolyicCell: '3 + 3 (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/mineral-swim-pro-3/'       
    },
    {
      name: 'Mineral Swim™️ PRO 2',
      display: 'V1 Pro 2',
      productCode: 'BR-600.02-VS',
      price: '$3,290',
      image: {
        gatsby: true,
        image: mineralSwimProducts[1].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 85,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '10 years',
      electrolyicCell: '3 + 3 (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/mineral-swim-pro-2/'       
    },
    {
      name: 'Mineral Swim™️ PRO 1',
      display: 'V1 Pro 1',
      productCode: 'BR-600.01-VS',
      price: '$2,990',
      image: {
        gatsby: true,
        image: mineralSwimProducts[2].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 50,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '10 years',
      electrolyicCell: '3 + 3 (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/mineral-swim-pro-1/'       
    },
    {
      name: 'Mineral Swim™️ O₃',
      display: 'V1 03',
      productCode: 'BR-600.04',
      price: '$2,390',
      image: {
        gatsby: true,
        image: mineralSwimProducts[2].images[0].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 120,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: false,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: true,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '@ 6-weeks & 6-months',
        welcomePack: true,
        poolWaterOptimizer: true,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '10 years',
      electrolyicCell: '-',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/mineral-swim-o3'       
    },
    // Ozone
    {
      name: 'Ozone 3000 Series',
      display: 'Ozone 3000',
      productCode: 'BR-500.59',
      price: '$2,690',
      image: {
        gatsby: true,
        image: ozoneSwimProducts[0].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 110,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '5 + 5 years (full + pro-rata)',
      electrolyicCell: '3 + 2 years (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/ozone-swim-3000/'       
    },
    {
      name: 'Ozone 2000 Series',
      display: 'Ozone 2000',
      productCode: 'BR-500.59',
      price: '$2,590',
      image: {
        gatsby: true,
        image: ozoneSwimProducts[1].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 85,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '5 + 5 years (full + pro-rata)',
      electrolyicCell: '3 + 2 years (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/ozone-swim-3000/'       
    },
    {
      name: 'Ozone 1000 Series',
      display: 'Ozone 1000',
      productCode: 'BR-500.57',
      price: '$2,490',
      image: {
        gatsby: true,
        image: ozoneSwimProducts[2].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 50,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '5 + 5 years (full + pro-rata)',
      electrolyicCell: '3 + 2 years (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/ozone-swim-3000/'       
    },
    {
      name: 'Ozone 1200iT Series',
      display: 'Ozone 1200iT',
      productCode: 'BR-500.56T',
      price: '$2,490',
      image: {
        gatsby: true,
        image: ozoneSwimProducts[3].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 120,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: false,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: true,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '5 + 5 years (full + pro-rata)',
      electrolyicCell: '-',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/ozone-swim-3000/'       
    },
    {
      name: 'Ozone 1200i Series',
      display: 'Ozone 1200i',
      productCode: 'BR-500.56',
      price: '$2,690',
      image: {
        gatsby: true,
        image: ozoneSwimProducts[4].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 80,000 L',
        nominalChlorineOutput: '',
        ozonePurification: true,
        chlorinator: false,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: true,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: true,
      poolWaterPurification: true,
      allergyFriendly: true,
      reducedChemicals: true,
      chloramineRemoval: true,
      safeSanitation: true,
      reducedEyeIritation: true,
     },
     warranty:{
      system: '3 years',
      ozoneCell: '5 + 5 years (full + pro-rata)',
      electrolyicCell: '-',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: false
    }  ,
    url:'https://www.maytronics.com.au/product/ozone-swim-3000/'       
    },
    // Ecoswim V2
    {
      name: 'Ecoswim® ES35',
      display: 'Ecoswim® ES35',
      productCode: '10-110-0004',
      price: '$2,199',
      image: {
        gatsby: false,
        image: EcoSwimv2img
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 100,000 L',
        nominalChlorineOutput: '33 G/HR + Ozone',
        ozonePurification: false,
        chlorinator: true,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: true,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost Mode',
        tdsLevel: '3500-6500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: false,
      poolWaterPurification: false,
      allergyFriendly: false,
      reducedChemicals: false,
      chloramineRemoval: false,
      safeSanitation: false,
      reducedEyeIritation: false,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '-',
      electrolyicCell: '5 years (3 years full + 2 years pro rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: true
    }  ,
    url:'https://www.maytronics.com.au/product/ecoswim-es35/',
    unavailable: false
    },
    {
      name: 'Ecoswim® ES25',
      display: 'Ecoswim® ES25',
      productCode: '10-110-0003',
      price: '$1,999',
      image: {
        gatsby: false,
        image: EcoSwimv2img
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 80,000 L',
        nominalChlorineOutput: '26 G/HR + Ozone',
        ozonePurification: false,
        chlorinator: true,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: true,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost Mode',
        tdsLevel: '3500-6500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: false,
      poolWaterPurification: false,
      allergyFriendly: false,
      reducedChemicals: false,
      chloramineRemoval: false,
      safeSanitation: false,
      reducedEyeIritation: false,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '-',
      electrolyicCell: '5 years (3 years full + 2 years pro rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: true
    }  ,
    url:'https://www.maytronics.com.au/product/ecoswim-es25/',
    unavailable: false
    },
    {
      name: 'Ecoswim® ES20',
      display: 'Ecoswim® ES20',
      productCode: '10-110-0002',
      price: '$1,799',
      image: {
        gatsby: false,
        image: EcoSwimv2img
      },
      specifications:{
        recommendedMaxPoolSize: 'up to 60,000 L',
        nominalChlorineOutput: '20 G/HR + Ozone',
        ozonePurification: false,
        chlorinator: true,
        futureModularSystemUpgrade: true,
        handHeldController: true,
        generatorModule: true,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: 'Standard / Eco / Boost Mode',
        tdsLevel: '3500-6500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: false,
      poolWaterPurification: false,
      allergyFriendly: false,
      reducedChemicals: false,
      chloramineRemoval: false,
      safeSanitation: false,
      reducedEyeIritation: false,
     },
     warranty:{
      system: '5 years (3 years full + 2 years pro rata)',
      ozoneCell: '-',
      electrolyicCell: '5 years (3 years full + 2 years pro rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: true
    }  ,
    url:'https://www.maytronics.com.au/product/ecoswim-es20/',
    unavailable: false
    },
    // Ecoswim V1
    {
      name: 'Eco Swim ES300',
      display: 'Eco Swim ES300',
      productCode: '10-104-0003-VS',
      price: '$2,322.31',
      image: {
        gatsby: true,
        image: ecoSwimProducts[0].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'Up to 110,000 Litres',
        nominalChlorineOutput: '',
        ozonePurification: false,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: false,
      poolWaterPurification: false,
      allergyFriendly: false,
      reducedChemicals: false,
      chloramineRemoval: false,
      safeSanitation: false,
      reducedEyeIritation: false,
     },
     warranty:{
      system: '2 years',
      ozoneCell: '',
      electrolyicCell: '2 + 3 years (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: true
    }  ,
    url:'https://www.maytronics.com.au/product/eco-swim-es300'
    },
    {
      name: 'Eco Swim ES200',
      display: 'Eco Swim ES200',
      productCode: '10-104-0002-VS	',
      price: '$1,972.21',
      image: {
        gatsby: true,
        image: ecoSwimProducts[1].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'Up to 90,000 Litres',
        nominalChlorineOutput: '',
        ozonePurification: false,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: false,
      poolWaterPurification: false,
      allergyFriendly: false,
      reducedChemicals: false,
      chloramineRemoval: false,
      safeSanitation: false,
      reducedEyeIritation: false,
     },
     warranty:{
      system: '2 years',
      ozoneCell: '',
      electrolyicCell: '2 + 3 years (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: true
    }  ,
    url:'https://www.maytronics.com.au/product/eco-swim-es200'
    },
    {
      name: 'Eco Swim ES100',
      display: 'Eco Swim ES100',
      productCode: '10-104-0001-VS',
      price: '$1,738.83',
      image: {
        gatsby: true,
        image: ecoSwimProducts[2].images[1].gatsbyImageData
      },
      specifications:{
        recommendedMaxPoolSize: 'Up to 50,000 Litres',
        nominalChlorineOutput: '',
        ozonePurification: false,
        chlorinator: true,
        futureModularSystemUpgrade: false,
        handHeldController: false,
        generatorModule: false,
        purifierModule: false,
        additionalChlorinator: false,
        deadSeaMineralCompatible: true,
        operatingModes: '',
        tdsLevel: '3500-4500 ppm',
        installationTime: '< 1 hour',
        poolsideHealthChecks: '-',
        welcomePack: false,
        poolWaterOptimizer: false,
        australia: true,
      },
     features:{
      dualSanitaion: false,
      poolWaterPurification: false,
      allergyFriendly: false,
      reducedChemicals: false,
      chloramineRemoval: false,
      safeSanitation: false,
      reducedEyeIritation: false,
     },
     warranty:{
      system: '2 years',
      ozoneCell: '',
      electrolyicCell: '2 + 3 years (full + pro-rata)',
     },
    upgrades:{phDrive: true,
      mineralSwim: true,
      ozoneUpgradable: true
    }  ,
    url:'https://www.maytronics.com.au/product/eco-swim-es100'
    },
  ]
}